<template>
  <van-nav-bar :title="article.title" left-arrow @click-left="goBack" :border="false" />
  <div class="article">
    <van-image class="article-image" :src="article.bannerImgUrl" />
    <!-- <div class="article-title"></div> -->
    <div v-show="article.timeStr" class="article-time">{{article.timeStr}}</div>
    <div class="article-content"><span>{{article.content}}</span></div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
  
export default {
  setup() {
    const router = useRouter();
    const goBack = () => router.back();
    return {
      goBack,
    };
  },
  
  data() {
    return {
      article: JSON.parse(localStorage.getItem('article')),
    };
  },

  methods: {},
};
</script>

<style lang="less">
.article {
  padding: 16px 16px 50px;
  .article-image {
    width: 100%;
  }
  .article-title {
    font-size: 24px;
    color: #000000;
    font-weight: 700;
  }
  .article-time {
    font-size: 16px;
    color: #6F6F93;
    font-weight: 400;
    margin-top: 10px;
  }
  .article-content {
    font-size: 16px;
    color: #28293D;
    line-height: 23px;
    font-weight: 400;
    margin-top: 26px;
    span {
      white-space: pre-wrap;
    }
  }
}
</style>
